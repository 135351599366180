import request from '@/utils/request'
let resquest = "/api/"

/**
 * 产品中心
 */

/*
 *零担
*/
// 零担成本
 export function lessThanTruckCostGetPageList(data) {
    return request({
        url: `${resquest}lessThanTruck/cost/getPageList`,
        method: 'post',
        data
    })
}
// 批量失效零担成本
 export function lessThanTruckCostGetExpire(data) {
    return request({
        url: `${resquest}lessThanTruck/cost/expire`,
        method: 'post',
        data
    })
}
// 批量删除零担成本
 export function lessThanTruckCostGetDelete(data) {
    return request({
        url: `${resquest}lessThanTruck/cost/delete`,
        method: 'post',
        data
    })
}
// 批量提交零担成本
 export function lessThanTruckCostGetSubmit(data) {
    return request({
        url: `${resquest}lessThanTruck/cost/submit`,
        method: 'post',
        data
    })
}
// 批量回退提交零担成本
 export function lessThanTruckCostGetCancelSubmit(data) {
    return request({
        url: `${resquest}lessThanTruck/cost/cancelSubmit`,
        method: 'post',
        data
    })
}
// 零担成本导出
export function lessThanTruckCostGetExport(data) {
    return request({
        url: `${resquest}lessThanTruck/cost/export`,
        method: 'post',
        data
    })
}
// 零担成本导入
export function lessThanTruckCostGetImportUrl() {
    return `${resquest}lessThanTruck/cost/import`
}
export function lessThanTruckCostGetImport(data) {
    return request({
        url: `${resquest}lessThanTruck/cost/import`,
        method: 'post',
        data
    })
}


/*
 * 城配
*/
//城配成本查询 
export function urbanDistributionCostList(data) {
    return request({
        url: `${resquest}urbanDistributionCost/list`,
        method: 'post',
        data
    })
}

//城配成本批量操作 
export function urbanDistributionCostBulkOperations(data) {
    return request({
        url: `${resquest}urbanDistributionCost/bulkOperations`,
        method: 'post',
        data
    })
}
//城配批量导入url
export function urbanDistributionCostImportUrl(data) {
    return `${resquest}urbanDistributionCost/import`
}
//城配批量导入
export function urbanDistributionCostImport(data) {
    return request({
        url: `${resquest}urbanDistributionCost/import`,
        method: 'post',
        data
    })
}
//城配导出
export function urbanDistributionCostExport(data) {
    return request({
        url: `${resquest}urbanDistributionCost/export`,
        method: 'post',
        data
    })
}

//城配服务查询 
export function urbanDistributionServiceList(data) {
    return request({
        url: `${resquest}urbanDistributionService/list`,
        method: 'post',
        data
    })
}
//城配服务批量操作 
export function urbanDistributionServiceBulkOperations(data) {
    return request({
        url: `${resquest}urbanDistributionService/bulkOperations`,
        method: 'post',
        data
    })
}
//城配服务导出 
export function urbanDistributionServiceExport(data) {
    return request({
        url: `${resquest}urbanDistributionService/export`,
        method: 'post',
        data
    })
}






/*
 * 零担
*/

// 零担服务
 export function lessThanTruckServiceGetPageList(data) {
    return request({
        url: `${resquest}lessThanTruck/service/getPageList`,
        method: 'post',
        data
    })
}
// 批量失效零担产品
export function lessThanTruckServiceExpire(data) {
    return request({
        url: `${resquest}lessThanTruck/service/expire`,
        method: 'post',
        data
    })
}
// 批量删除零担产品
export function lessThanTruckServiceDelete(data) {
    return request({
        url: `${resquest}lessThanTruck/service/delete`,
        method: 'post',
        data
    })
}
// 批量发布零担产品
export function lessThanTruckServiceRelease(data) {
    return request({
        url: `${resquest}lessThanTruck/service/release`,
        method: 'post',
        data
    })
}
// 批量回退发布零担产品
export function lessThanTruckServiceCancelRelease(data) {
    return request({
        url: `${resquest}lessThanTruck/service/cancelRelease`,
        method: 'post',
        data
    })
}

// 零担产品导出
export function lessThanTruckServiceCancelExport(data) {
    return request({
        url: `${resquest}lessThanTruck/service/export`,
        method: 'post',
        data
    })
}




//拖车成本
export function trailCarCostList(data) {
    return request({
        url: `${resquest}trailCarCost/list`,
        method: 'post',
        data
    })
}


//拖车成本批量操作 
export function trailCarCostBulkOperations(data) {
    return request({
        url: `${resquest}trailCarCost/bulkOperations`,
        method: 'post',
        data
    })
}

//拖车成本导出 
export function trailCarCostExport(data) {
    return request({
        url: `${resquest}trailCarCost/export`,
        method: 'post',
        data
    })
}

//拖车服务导出 
export function trailCarServiceExport(data) {
    return request({
        url: `${resquest}trailCarService/export`,
        method: 'post',
        data
    })
}


//拖车成本批量导入url
export function trailCarCostImportUrl(data) {
    return `${resquest}trailCarCost/import`
}
//拖车成本批量导入
export function trailCarCostImport(data) {
    return request({
        url: `${resquest}trailCarCost/import`,
        method: 'post',
        data
    })
}

//拖车服务
export function trailCarServiceList(data) {
    return request({
        url: `${resquest}trailCarService/list`,
        method: 'post',
        data
    })
}

//拖车服务批量操作 
export function trailCarServiceBulkOperations(data) {
    return request({
        url: `${resquest}trailCarService/bulkOperations`,
        method: 'post',
        data
    })
}

/*
* 拖车产品
*/ 

//拖车产品查询 
export function trailCarServiceProductSelect(data) {
    return request({
        url: `${resquest}trailCarCost/productSelect`,
        method: 'post',
        data
    })
}
//散货产品查询 
export function productQueryBulkCargoSelect(data) {
    return request({
        url: `${resquest}productQuery/bulkCargoSelect`,
        method: 'post',
        data
    })
}




// 模板导出
export function baseTemplateImportGetFileByCode(data) {
    return request({
        url: `${resquest}base/templateImport/getFileByCode`,
        method: 'post',
        data
    })
}



